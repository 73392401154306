<template>
  <nav class="top-nav" id="idTopNav">
    <a href="/" title="Калкулатор" class="active">калкулатор.com</a>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idFinance')">Финанси
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idFinance">
        <a href="/iban" title="IBAN валидатор">IBAN</a>
        <a href="/otstapka" title="калкулатор за отстъпка">Отстъпка</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idMathematics')">Математика
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idMathematics">
        <a href="/kvadraten-koren" title="калкулатор за квадратен корен">Квадратен корен</a>
        <a href="/radian" title="радиан калкулаторr">Радиан</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idPhysics')">Физика
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idPhysics">
        <a href="/temperatura" title="температурен преобразувател">Температура</a>
      </div>
    </div>

    <div class="dropdown">
      <button class="dropbtn" v-on:click="menuClick('idTime')">Време
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" id="idTime">
        <a href="/chasova-razlika" title="часова разлика">Часова разлика</a>
      </div>
    </div>

    <a href="javascript:void(0);" style="font-size:15px;" class="icon" v-on:click="hamburgerMenuClick()">&#9776;</a>
  </nav>
</template>

<script>
export default {
  name: "TopNav",
  methods: {
    hamburgerMenuClick: function () {
      var x = document.getElementById("idTopNav");
      if (x.className === "top-nav") {
        x.className += " responsive";
      } else {
        x.className = "top-nav";

        var z = document.getElementById("idMathematics");
        z.className = "dropdown-content";

        var p = document.getElementById("idPhysics");
        p.className = "dropdown-content";

      }
    },
    /**
     *
     * @param {string} selectedId
     */
    menuClick: function (selectedId) {
      var x = document.getElementById(selectedId);
      if (x.className === "dropdown-content") {
        x.className += " aaaa";
      } else {
        x.className = "dropdown-content";
      }
    }
  }
}
</script>

<style scoped>
.top-nav {
  overflow: hidden;
  background-color: #333;
  font-family: Arial !important;
  font: 1em/1em Arial;
}

.top-nav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #0063dc;
  color: white;
}

.top-nav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.top-nav a:hover, .dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

@media only screen and (min-width: 650px) {
  .dropdown:hover .dropdown-content {
    display: block;
  }
}

.aaaa {
  display: block;
}

@media screen and (max-width: 740px) {
  .top-nav {
    margin-left: -10px;
    margin-right: -10px;
  }

  .top-nav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }

  .top-nav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 740px) {
  .top-nav.responsive {
    position: relative;
  }

  .top-nav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .top-nav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .top-nav.responsive .dropdown {
    float: none;
  }

  .top-nav.responsive .dropdown-content {
    position: relative;
  }

  .top-nav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
</style>